<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="productReportList && productReportList.length > 0 && showHeaders.length > 0 ? '360' : ''"
              :style="{maxHeight: !productReportList ? '340px' : ''}"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="showHeaders"
              :items="productReportList"
              style="word-break: break-word"
              :search="search"
              item-class="py-4"
              fixed-header
              :loading="isProgressCircular"
              :items-per-page="limitData"
              :page.sync="page"
              @pagination="newPagination"
              @dblclick:row="doubleClick"
              @page-count="pageCount = $event"
              must-sort
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <span :ref="`row-${item.id}`">{{ item.id }}</span>
              </template>
              <template v-slot:[`item.last_sale_date`]="{ item }">
                <span>{{profileById.date_format ? formatDateDDMMYYYY(item.last_sale_date) : parseDateYYYYMMDD(item.last_sale_date)}}</span>
              </template>
              <template v-slot:[`item.last_purchase_date`]="{ item }">
                <span>{{profileById.date_format ? formatDateDDMMYYYY(item.last_purchase_date) : parseDateYYYYMMDD(item.last_purchase_date)}}</span>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("product.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf class="mx-1 mb-1" :disabled="productReportList.length" :model="productReportList" :payload="payload" type="ItemReportPDF"/>
                  <ExcelAndResetFilterCommon :data="productReportList.length" @downloadExcelFile="downloadExcelFile" @resetFilters="resetFilters"/>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="4">
                      <v-autocomplete
                        hide-details="auto"
                        v-model="selectedProductGroup"
                        :items="productGroupValue"
                        item-text="description"
                        item-value="id"
                        clearable
                        flat
                        @input="buildUrl"
                        return-object
                        :label="$t('product.field.group')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="text-center mt-3">
                      <ExecuteAndRestCommon @reset="reset" @paginate="paginate(false)" type="Product" />
                      <v-dialog
                        dense
                        v-model="dialog"
                        scrollable
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            color="primary"
                            class="float-end"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left> mdi-view-comfy </v-icon>
                            {{ $t("product.fieldView") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-row class="align-center ma-0">
                            <v-col>
                              <v-card-title class="primary--text">
                                <v-icon left color="primary">mdi-land-fields</v-icon>
                                  <h6 class="font-weight-regular">
                                    {{ $t("product.selectFields") }}
                                  </h6>
                              </v-card-title>
                            </v-col>
                            <v-col>
                              <v-btn x-small color="primary" class="float-end mx-3" @click="updateHeader">{{ $t('product.update') }}</v-btn>
                            </v-col>
                          </v-row>
                          <v-divider />
                          <v-card-text style="height: 300px;">
                            <v-select
                              dense
                              class="mt-2"
                              hide-details="auto"
                              v-model="selectedHeaders"
                              :items="headers"
                              item-text="text"
                              item-value="id"
                              return-object
                              clearable
                              multiple
                              outlined
                              :menu-props="{ bottom: true, offsetY: true, maxHeight: 200, overflowY: true }"
                              :label="$t('product.selectFields')"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  @mousedown.prevent
                                  @click="toggle"
                                >
                                  <v-list-item-action>
                                    <v-checkbox dense v-model="selectFields" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{$t('product.selectAll')}}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2" />
                              </template>
                            </v-select>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("product.debitReport") }}
                      </h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        :autofocus="type ? true : false"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{ productReportList && productReportList.length && showHeaders.length > 0 ? numberOfRows : 0 }}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination :total-visible="5" v-model="page" :length="pageCount"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{ $t("common.noDataFound") }}</v-card-text>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import exportFromJSON from 'export-from-json';
import { getAuxiliaryZtableValueByName } from "@/utils";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";

export default {
  name: "ProductReport",
  props: ['type'],
  components: {
    ListSkeleton,
    HtmlToPdf,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon
  },
  data() {
    return {
      sortByValue: null,
      sortDescValue: null,
      itemLimit: 10,
      selectedProductGroup: null,
      productGroupValue: [],
      listSkeleton: false,
      dialog: false,
      selectedHeaders: [],
      selectFields: false,
      search: this.$route.query.search || "",
      limitData: this.limit,
      limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
      page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
      pagination: null,
      numberOfRows: "",
      custom: {id: 111111111111111, description: this.$t('invoice.empty')},
      isProgressCircular: false,
    };
  },
  computed: {
    params() {
      return {
        query: this.search,
        item_group_id: this.selectedProductGroup?.id,
        item_id: this.selectedItem?.id,
      };
    },
    payload() {
      return {
        item_group_id: this.selectedProductGroup?.description,
      }
    },
    selectAllHeaders() {
      return this.selectedHeaders.length === this.headers.length;
    },
    ...mapGetters({
      productReportList: "product/productReportList",
      locale: "locale",
      showHeaders: "productReport/showHeaders",
      accessRight: 'accessRight',
      pageChange: "productReport/pageChange",
      profileById: 'profile/profileById',
      rowIndex: "product/rowIndex",
    }),
    headers() {
      return [
        { text: this.$t("product.id"), value: "id", width: "130px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.itemDescription"), value: "description", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.foreignDescription"), value: "foreign_description", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.itemCode"), value: "item_code", width: "140px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.barcode"), value: "barcode", width: "140px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.group"), value: "group.description", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.lastSaleCustomer"), value: "last_sale_customer", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.lastSalePrice"), value: "last_sale_price", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.lastSaleDate"), value: "last_sale_date", width: "180px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.lastPurchaseSupllier"), value: "last_purchase_supllier", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.lastPurchasePrice"), value: "last_purchase_pice", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.lastPurchaseDate"), value: "last_purchase_date", width: "180px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.saleAccountingNo"), value: "sale_accounting_no", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.salePrice"), value: "sale_price", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.minimumSalePrice"), value: "minimum_sale_price", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.purchaseAccountingNo"), value: "purchase_accounting_no", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.purchasePrice"), value: "purchase_price", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.itemWithoutVAT"), value: "item_without_vat", width: "160px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.createdAt"), value: "created_at", width: "180px", align: "start", class: "px-1", sortable: true },
        { text: this.$t("product.field.updatedAt"), value: "updated_at", width: "180px", align: "start", class: "px-1", sortable: true },
      ];
    },
    empty() {
      return this.$t('invoice.empty')
    }
  },
  created() {
    if(this.showHeaders.length == 0) {
      this.$store.commit('productReport/SHOW_HEADERS', this.headers)
    }
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
  },
  beforeDestroy() {
    this.$store.commit("productReport/SHOW_PAGE_CHANGE", {page: this.pagination.page, limit: this.pagination.itemsPerPage})
  },
  destroyed() {
    if (!this.$route.params.product_id) {
      this.$store.commit('product/SET_PRODUCT_REPORT', [])
    }
  },
  watch: {
    empty() {
      this.custom.description = this.empty
    },
    pagination: {
      handler(newVal) {
        if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10
        }
      },
      deep: true
    },
    type: {
      handler() {
        this.search = '';
      }
    },
    '$route.query': {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length) 
        this.search = this.$route.query.search || ""
      }
    },
    selectedHeaders() {
      if (this.selectedHeaders.length === this.headers.length) {
        this.selectFields = true
      } else {
        this.selectFields = false
      }
    },
    locale() {
      this.updateHeader();
    }
  },
  async mounted() {
    this.listSkeleton = true;
    this.selectedHeaders = this.showHeaders
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
    this.$store.dispatch("product/GetProduct");
    getAuxiliaryZtableValueByName(3).then((tableProductValue) => {
      if (Object.keys(tableProductValue).length > 0) {
        this.productGroupValue = tableProductValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.productGroupValue = [this.custom, ...this.productGroupValue]
      }
    });
    let rowIndex = this.$store.state.product.rowIndex;
    if (rowIndex && this.productReportList.length > 0) {
      this.$refs[`row-${rowIndex}`].scrollIntoView({ block: "center" })
    }
    this.listSkeleton = false;
  },
  methods: {
    parseDateYYYYMMDD:parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    doubleClick(event, {item}) {
      const path = 'item/editItem/' + item.id;
      this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
      this.$store.commit('product/SET_ROWINDEX', item.id);
    },
    newPagination(val) {
      this.pagination = val
      this.numberOfRows = val.itemsLength
    },
    updateHeader() {
      this.$store.commit('productReport/SHOW_HEADERS', this.headers.filter(s => this.selectedHeaders.some(d => d.value === s.value)))
      this.selectedHeaders = this.showHeaders
      this.dialog = false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllHeaders) {
          this.selectedHeaders = []
        } else {
          this.selectedHeaders = this.headers.slice()
        }
      })
    },
    reset() {
      this.search = null;
      this.$store.commit('product/SET_PRODUCT_REPORT', [])
      this.page = 1;
      this.limit = 10;
      this.limitData = this.limit;
    },
    resetFilters() {
      this.sortByValue = null;
      this.sortDescValue = null;
      this.selectedItem = null;
      this.selectedProductGroup = null;
      this.$store.commit("product/SET_PRODUCT_REPORT_QUERY", {});
      this.buildUrl();
    },
    downloadExcelFile() {
      let self = this;
      let data = self.productReportList.map(o => Object.fromEntries(self.selectedHeaders.map(k => [k.text, k.value.includes('.')?
      o[`${k.value.split('.')[0]}`] && Object.keys(o[`${k.value.split('.')[0]}`]).length > 0 ? o[`${k.value.split('.')[0]}`][`${k.value.split('.')[1]}`] : o[`${k.value.split('.')[0]}`]:o[`${k.value}`]])))
      data.forEach((el)=> {
        for (let x in el) {
          if (!el[x]) {
            el[x] = ''
          }}
      })
      exportFromJSON({data: JSON.parse(JSON.stringify(data)), fileName: "itemReport", exportType: exportFromJSON.types.xls})
    },
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0).toFixed(2);
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      obj.search = this.search;
      obj.itemId = this.selectedItem?.id;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj.productGroupId = this.selectedProductGroup?.id;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "itemId" && val === null) return acc;
        else if (key === "productGroupId" && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$store.commit("product/SET_PRODUCT_REPORT_QUERY", obj);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.itemLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate() {
      const {
        item_group_id,
        item_id,
      } = this.params;
      // make params for where_and
      let concateWhereAnd = "";
      let whereAndVal = "|";
      const payloadData = {
        where: item_id ? "item_id|" + item_id : undefined,
        whereAnd: item_group_id
          ? "group_id|" + item_group_id
          : undefined,
        where_and:
          concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
            ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
            : undefined,
        empty: 'group_id|' + 'NULL'
      }
      if (item_group_id && item_group_id === 111111111111111) {
        payloadData.empty, delete payloadData.whereAnd
      } else {
        payloadData.whereAnd, delete payloadData.empty
      }

      this.isProgressCircular = true;
      await this.$store.dispatch("product/GetProductReport", payloadData).then(() => {
        this.isProgressCircular = false;
        this.$refs.search.focus();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>